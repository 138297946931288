form {
  .MuiTextField-root {
    &.name,
    &.email,
    &.phone {
      width: 100%;
    }
  }

  .MuiTextField-root.query {
    width: 100%;
  }
}

.contact {
  padding: 2%;
}
