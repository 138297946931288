.page {
  position: relative;
  top: 7em;
  margin: auto;
  width: 100%;
  max-width: 1000px;
  padding-bottom: 4em;
}

@media (max-width: 650px) {
  .page {
    top: 3em;
  }
}
