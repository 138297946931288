.header-cont {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5em;
  border-bottom: 1px solid orange;
  box-shadow: 1px 1px 10px #ccc;
  background-color: #f0f0f0;
  z-index: 100;

  .header {
    width: 1000px;
    max-width: 100%;
    margin: auto;

    .name {
      padding-left: 1em;
      font-weight: bold;
    }

    .avatar {
      display: inline-flex;
    }
  }
}

@media (max-width: 650px) {
  .header-cont {
    height: 2.5em;

    .header {
      display: flex;
      align-items: center;

      .name {
        width: 400px;
      }
    }
  }
}
